import type { ReviewDomain } from '#app/domain/review.domain';
import type { SerializeFrom } from '@remix-run/node';

import { bookTimeAgo } from '#app/utils/date';
import { cn } from '#app/utils/misc';
import { Link } from '@remix-run/react';
import { cva } from 'class-variance-authority';
import { DateTime } from 'luxon';
import React from 'react';
import { isAndroid, isBrowser, isIOS } from 'react-device-detect';
import { P, match } from 'ts-pattern';

import EmptyBookImage from '../../../../other/png/empty-book.png';
import { Button } from '../button/button';
import { TextButton } from '../button/text-button';
import { Icon } from '../icon';
import ProgressiveImage from '../progressive-image';

export interface BookKeywordsTag {
  disabledKeyword?: string;
  keywords: string[];
}

export interface BookUserTag {
  id: number;
  imageUrl: string;
  job: string;
  name: string;
}

interface BookProps {
  actions?: 'read' | 'saved' | 'wantRead';
  book: SerializeFrom<ReviewDomain['source']>;
  disabled?:boolean;
  hoverOff?: boolean;
  loading?: string;
  onClick?: () => void;

  review?: SerializeFrom<ReviewDomain>;
  

  tag?: BookKeywordsTag | BookUserTag
}
const variants = cva(
  'grid grid-flow-row max-w-[11.25rem] relative rounded-2xl',
  {
    variants: {
      animate: {
        in : 'hover:animate-book-item-in animate-book-item-out',
        out: 'animate-book-item-out',
      },
    },
  },
);
// eslint-disable-next-line complexity
const Book: React.FC<BookProps & React.ButtonHTMLAttributes<HTMLDivElement>> = (props) => {
  const [animate, setAnimate] = React.useState<'in' | 'out'>();
  const isMobile = isAndroid || isIOS;

  return (
    <article>
      {
        isMobile && (
          <>
            {
              bookTimeAgo(DateTime.fromISO(props.review?.createdAt || props.book.createdAt)) && 
              <div className='grid grid-flow-col justify-start mb-2 items-center gap-1'>
                <div className='bg-pink-10 rounded-full px-1 py-0.5 grid max-w-[2rem] text-center'>
                  <span className='text-pink-70 text-caption-3-medium font-pretendardMedium'>New</span>
                </div>
                <span className='text-center text-gray-60 text-caption-3-medium font-pretendardMedium'>{bookTimeAgo(DateTime.fromISO(props.review?.createdAt || props.book.createdAt))}</span>
              </div>
            }
          </>
        )
      }
      <section
        className={
          cn(
            cn(variants({ animate: animate })),
            props.className,
          )
        }
        onClick={(e) => {
          e.preventDefault();
          props.onClick?.();
        }}
        onMouseEnter={() => {
          if(isBrowser && !props.hoverOff) {
            setAnimate('in'); 
          }
        }}
        onMouseLeave={() => {
          if(isBrowser && !props.hoverOff) {
            setAnimate('out'); 
          }
        }}
        style={props.style}>
        {
          !isMobile && 
            <div className={
              cn(
                !props.hoverOff && 'group hover:bg-gradient-to-b from-alpha-2 to-alpha-3 z-10 hover:cursor-pointer',
                !props.disabled && 'active:bg-gradient-to-b from-alpha-2 to-alpha-3 z-10',
                'grid content-between absolute rounded-xl border border-gray-20',
                'w-full h-full'
              )
            }>
              {
                bookTimeAgo(DateTime.fromISO(props.review?.createdAt || props.book.createdAt)) && 
                  <div className='absolute group group-hover:opacity-0 m-2 bg-pink-10 h-[1.125rem] rounded-[5px] px-1 py-0.5 grid max-w-[3rem] text-center'>
                    <span className='text-pink-70 text-tiny-medium xs:font-sfProMedium sm:font-pretendardMedium'>{bookTimeAgo(DateTime.fromISO(props.review?.createdAt || props.book.createdAt))}</span>
                  </div>
              }
              <div className='opacity-0 group group-hover:opacity-100 grid justify-end pt-2 pr-2'>
                {
                  match(props.actions)
                    .returnType<React.ReactNode>()
                    .with('read', () => (
                      <Button onClick={(e) => e.stopPropagation()} rounded={'lg'} size='small' variant={'black'}>
                    내 코멘트 보기
                        <input name='bookId' type='hidden' value={props.book.id} />
                        <input name='bookActionType' type='hidden' value={'read'} />
                      </Button>
                    ))
                    .with('saved', () => (
                      <Button loading={props.loading === 'true' ? 'true' : 'false'} onClick={(e) => e.stopPropagation()} rounded={'lg'} size='small' variant={'black'}>
                        <Icon name='fill-bookmark' size='small'/>
                    저장됨
                        <input name='bookId' type='hidden' value={props.book.id} />
                        <input name='bookActionType' type='hidden' value={'saved'} />
                      </Button>
                    ))
                    .with('wantRead', () => (
                      <Button loading={props.loading === 'true' ? 'true' : 'false'} onClick={(e) => e.stopPropagation()} rounded={'lg'} size='small' variant={'primary'}>
                        <Icon name='regular-bookmark' size='small'/>
                    읽고 싶어요
                        <input name='bookId' type='hidden' value={props.book.id} />
                        <input name='bookActionType' type='hidden' value={'wantRead'} />
                      </Button>
                    ))
                    .otherwise(() => null)
                }
              </div>
              <section className='opacity-0 group-hover:opacity-100 grid px-4 pb-5'>
                <h1 className='text-caption-1-bold xs:font-sfProBold sm:font-pretendardBold text-white text-ellipsis overflow-hidden line-clamp-3'>
                  {props.book.title}
                </h1>
              </section>
            </div>
        }
        <ProgressiveImage
          alt='책 이미지'
          className='rounded-xl w-full'
          placeholderSrc={EmptyBookImage}
          src={props.book.image || 'https://pub-1252dd48f2ce47d69b33c3df0beff5e0.r2.dev/empty_book.png'}
        />
        <div className='absolute w-full bottom-0 shadow-2xl h-full rounded-2xl'></div>
      </section>
      <>
        <Tag {...props} />
      </>
    </article>
  );
};

const Tag: React.FC<BookProps> = (props) => {
  return match(props.tag)
    .with({
      name: P.not(P.nullish),
    }, (v) => (
      <Link to={`/my-library/${v.id}/library`}>
        <div className="items-stretch flex justify-between gap-2 mt-3 hover:cursor-pointer">
          <img
            alt="프로필 이미지"
            className="rounded-[0.375rem] aspect-square object-contain object-center w-8 h-8 overflow-hidden max-w-full"
            src={v.imageUrl}
          />
          <div className="items-stretch self-center flex grow basis-[0%] flex-col my-auto">
            <div className="text-gray-90 text-caption-1-bold xs:font-sfProBold sm:font-pretendardBold tracking-normal whitespace-nowrap">
              <span>{v.name}</span>
            </div>
            <div className="text-gray-60 text-caption-3-regular xs:font-sfProLight sm:font-pretendardLight tracking-normal whitespace-nowrap">
              <span>{v.job}</span>
            </div>
          </div>
        </div>
      </Link>
    ))
    .with({
      disabledKeyword: P.not(P.nullish),
      keywords       : P.not(P.nullish),
    }, (v) => {
      return (
        <div className='flex flex-wrap gap-2 pt-3 max-w-[10.25rem]'>
          {
            v.keywords.map(k => {
              return (
                <Link key={k} to={`/keyword-library/${k.replaceAll('/', '-')}`}>
                  <TextButton className='grid' disabled={k === v.disabledKeyword} size='small'>
                    {k === v.disabledKeyword 
                      ? <span className='grid grid-flow-col gap-0.5 xs:font-sfProBold sm:font-pretendardBold text-gray-90'>
                        <Icon name='regular-hashtag' />{k}
                      </span> 
                      : <span className='grid grid-flow-col gap-0.5'><Icon name='regular-hashtag' />{k}</span>} 
                  </TextButton>
                </Link>
              );
            })
          }
        </div>
      );
    })
    .with({
      disabledKeyword: P.nullish,
      keywords       : P.not(P.nullish),
    }, (v) => {
      return (
        <div className='flex flex-wrap gap-2 pt-3 max-w-[10.25rem]'>
          {
            v.keywords.map(k => {
              return (
                <Link key={k} to={`/keyword-library/${k.replaceAll('/', '-')}`}>
                  <TextButton className='grid' disabled={k===v.disabledKeyword} size='small'>
                    {k === v.disabledKeyword 
                      ? <span className='grid grid-flow-col gap-0.5 font-pretendardBold text-gray-90'>
                        <Icon name='regular-hashtag' />{k}
                      </span> 
                      : <span className='grid grid-flow-col gap-0.5'><Icon name='regular-hashtag' />{k}</span>} 
                  </TextButton>
                </Link>
              );
            })
          }
        </div>
      );
    })
    .otherwise(() => null);
};

export default Book;